<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="6" md="4" lg="6">
                    <ValidationProvider name="evaluation_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('evaluation_name')">
                            <evaluation-selectbox v-model="formData.evaluation_id"></evaluation-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6" md="4" lg="6">
                    <ValidationProvider name="assessment_type_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('assessment_type_name')">
                            <evaluation-assessments-selectbox v-model="formData.assessment_type_id"></evaluation-assessments-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" md="4" lg="6">
                    <b-form-group :label="$t('affect_ratio')">
                        <ValidationProvider name="affect_ratio" rules="required" v-slot="{ errors }">
                            <b-input-group class="mb-1">
                                <b-form-input type="number" v-model="formData.affect_ratio" :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="6" md="4" lg="6">
                    <ValidationProvider name="rank" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input type="number" v-model="formData.rank" :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="createForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components


// Services
import EvaluationAssessmentService from "@/services/EvaluationAssessmentService";


// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import EvaluationSelectbox from "@/components/interactive-fields/EvaluationSelectbox.vue";
import EvaluationAssessmentsSelectbox from "@/components/interactive-fields/EvaluationAssessmentsSelectbox.vue";

export default {
    components: {
        EvaluationAssessmentsSelectbox,
        EvaluationSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {
                evaluation_id: null,
                evaluation_name: null,
                assessment_type_id: null,
                assessment_type_name: null,
                affect_ratio: null,
                rank: null,
            },
            formLoading: false,
        }
    },
    methods: {
        async createForm() {
            if (this.checkPermission('evaluationassessment_store')) {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    EvaluationAssessmentService.store(this.formData)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$emit('createFormSuccess', true);
                        })
                        .catch(e => {
                            this.showErrors(e);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>

