<template>
    <div>
      <ValidationObserver ref="formModalValidate">
        <b-row>
          <b-col cols="6" md="4" lg="6">
            <ValidationProvider name="evaluation_id" rules="required" v-slot="{ errors }">
                <b-form-group :label="$t('evaluation_name')">
                    <evaluation-selectbox v-model="formData.evaluation_id"></evaluation-selectbox>
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                </b-form-group>
            </ValidationProvider>
          </b-col>
            <b-col cols="6" md="8" lg="6">
                <ValidationProvider name="assessment_type_id" rules="required" v-slot="{ errors }">
                    <b-form-group :label="$t('assessment_type_name')">
                        <evaluation-assessments-selectbox v-model="formData.assessment_type_id"></evaluation-assessments-selectbox>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-row>
              <b-col cols="6" md="4" lg="6">
                  <ValidationProvider name="affect_ratio" rules="required" v-slot="{ errors }">
                      <b-form-group :label="$t('affect_ratio')">
                          <b-form-input type="number"
                              v-model="formData.affect_ratio"
                          />
                          <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                      </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col cols="6" md="8" lg="6">
                  <ValidationProvider name="rank" rules="required" v-slot="{ errors }">
                      <b-form-group :label="$t('rank')">
                          <b-form-input type="number" v-model="formData.rank" :state="errors[0] ? false : null"/>
                          <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                      </b-form-group>
                  </ValidationProvider>
              </b-col>
          </b-row>
        <div class="d-flex justify-content-center mt-2">
          <b-button variant="primary" @click="updateForm" :disabled="formLoading">
            {{ $t('save') }}
          </b-button>
        </div>
      </ValidationObserver>
    </div>
</template>

<script>
// Components

// Services
import EvaluationAssessmentService from "@/services/EvaluationAssessmentService";

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import EvaluationAssessmentsSelectbox from "@/components/interactive-fields/EvaluationAssessmentsSelectbox.vue";
import EvaluationSelectbox from "@/components/interactive-fields/EvaluationSelectbox.vue";

export default {
    components: {
        EvaluationSelectbox,
        EvaluationAssessmentsSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                evaluation_id: null,
                evaluation_name: null,
                assessment_type_id: null,
                assessment_type_name: null,
                affect_ratio: null,
                rank: null,

            },
            coordinatorValue: {},
            formLoading: false,
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            EvaluationAssessmentService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        evaluation_id: data.evaluation_id,
                        assessment_type_id: data.assessment_type_id,
                        affect_ratio: data.affect_ratio,
                        rank: data.rank,

                    }
                    if (data.coordinator_id) {
                        this.coordinatorValue = {value: data.coordinator_id, text: data.coordinator_name}
                    }

                })
                .catch(e => {
                    this.showErrors(e)
                })
        },

        async updateForm() {
            if (this.checkPermission('evaluationassessment_update')) {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    EvaluationAssessmentService.update(this.formId, this.formData)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>
